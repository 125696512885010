// import algoliasearch from 'algoliasearch/lite';
// import instantsearch from 'instantsearch.js/es';
// import { searchBox, hits, refinementList } from "instantsearch.js/es/widgets";

import { webflowService } from "./webflow-service";

class CollectionPageService {
	constructor() { }

	setupCollectionPage() {
		addInfiniteScroll();
		addSorting();
		addFiltering();
	}
}

function addSorting() {
	document.querySelectorAll<HTMLElement>('[data-sort]').forEach(trg => {
		trg.addEventListener('click', () => {
			if(!trg.dataset.sort) return;
			const elements:HTMLElement[] = [];
			document.querySelectorAll(trg.dataset.sort).forEach(el => {
				const sortEl = el.closest<HTMLElement>('[role=listitem]');
				if(!sortEl) return;
				sortEl.dataset.sortVal = el.textContent || '';
				elements.push(sortEl);
			});

			const parent = elements[0].closest('[role=list]')!;
			//by sorting and re appending existing nodes we update sort order of the nodes on the page
			let comparer = (a,b) => a.dataset.sortVal!.localeCompare(b.dataset.sortVal!);
			if(trg.dataset.sortDir === 'desc') {
				comparer = (a,b) => b.dataset.sortVal!.localeCompare(a.dataset.sortVal!);
				trg.dataset.sortDir = 'asc';
			} else {
				trg.dataset.sortDir = 'desc';
			}
			elements.sort(comparer).forEach(el => parent.append(el));
		});
	});
}

function addFiltering() {
	document.querySelectorAll<HTMLElement>('[data-filter][data-filter-target]').forEach(async box => {
		const [collection, fieldSlug] = box.dataset.filter!.split('.');
		const items = await webflowService.getCollectionDropdown(collection, fieldSlug);
		const html = items.reduce((html, i) => html + `<label role=listitem><input type=checkbox value='${i.name}'>${i.name}</label>`, '');
		box.innerHTML = `<div class='filter-list'>${html}</div>`;
		const filters = new Set<string|null>();
		box.addEventListener('change', ev => {
			const input = ev.target as HTMLInputElement;
			input?.checked ? filters.add(input.value) : filters.delete(input.value);
			filterItems(filters, box);
		});
	});
	// document.querySelectorAll<HTMLElement>('[data-filter][data-filter-target]').forEach(box => {
	// 	const [index, attribute] = ['Blog', 'topic'];//box.dataset.filter!.split('.');
	// 	instantsearch({
	// 		indexName: index,
	// 		searchClient: algoliasearch('UXUF8DC1Z9', 'c5559498012deb1026af2a40c1161014'),
	// 		onStateChange: ({uiState}) => filterItems(uiState[index].refinementList?.[attribute], box)
	// 	}).addWidgets([
	// 		refinementList({ container: box, attribute: attribute })
	// 	])
	// 	.start();
	// });
}

function filterItems(filters: Set<string|null>, container: HTMLElement) {
	const showAll = filters.size === 0;
	const target = container.dataset.filterTarget!;

	// one listitem may contain multiple targets
	// where "target" contains actual text we want to match on
	// but we apply 'hide' class to entire listitem
	document.querySelectorAll(target).forEach(el => {
		const filterEl = el.closest<HTMLElement>('[role=listitem]');
		filterEl?.classList.toggle('hide', !(showAll || filters.has(el.textContent)))
	});
}

function addInfiniteScroll() {
	const template = document.querySelector<HTMLElement>('[data-wf-template]');
	const dynList = template?.closest<HTMLElement>('.w-dyn-list');
	if (!dynList || !template) return;

	let fetchInProgress:Promise<void> | null = null;
	//uses WF next page URL from pagination to fetch next page items
	let nextUrl = dynList.querySelector('.w-pagination-next')?.getAttribute('href');

	// start fetch on scroll as soon as the bottom of the template is visible
	window.addEventListener('scroll', () => {
		if(!nextUrl || fetchInProgress) return;
		const bottom = template.getBoundingClientRect().bottom;
		//console.log(bottom, window.innerHeight);
		const endOfItems = window.innerHeight >= bottom;
		if(!endOfItems) return;
		fetchInProgress = fetch(nextUrl).then(r => r.text()).then(nextPage => {
			const nextPageDom = $(nextPage);
			const nextPageItems = nextPageDom.find('.w-dyn-list .w-pagination-wrapper').parent().find('.w-dyn-item');
			const currentPageContainer = $(dynList).find('.w-dyn-items');
			currentPageContainer.append(nextPageItems);
			
			fetchInProgress = null;
			nextUrl = nextPageDom.find('.w-pagination-next').attr('href');	
		});
	});
}

export const collectionPagesService = new CollectionPageService();