class WebflowService {
    private async getCollection(collectionId: string): Promise<any> {
        return fetch('/api/webflow/collection?cid=' + collectionId);
    }

    async getCollectionDropdown(collectionId: string, fieldSlug: string):Promise<any[]> {
        const collection = await this.getCollection(collectionId).then(r => r.json());

        const field = collection.fields.find(f => f.slug === fieldSlug && f.type === 'Option');
        if(!field) return [];
        return field.validations.options;
    }
}

export const webflowService = new WebflowService();